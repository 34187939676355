var site = site || {};
site.account = site.account || {};
site.signin = site.signin || {};
site.userInfoCookie = site.userInfoCookie || {};
site.userInfoCookie.getValue = site.userInfoCookie.getValue || function() { return ''; };
var settings = Drupal.settings || {};
var settingsLoyalty = typeof settings.loyalty === 'object' ? settings.loyalty : {};
var settingsLoyaltyRegistrationPopup = typeof settingsLoyalty === 'object'
  && typeof settingsLoyalty.registration_popup === 'object' ? settingsLoyalty.registration_popup : {};
var validate_password_enabled = Drupal.settings.validate_password_enabled || 0;

(function ($) {
  Drupal.behaviors.signIn = {
    attach: function (context, settings) {
      Drupal.ELB.loadPersistenUserCookie();
      var pathname = window.location.pathname;
      var signedIn = site.userInfoCookie.getValue('signed_in');
      var forceReturn = false;
      var returnURL = null;
      var $dataPrivacyCheckbox;
      var $commercialPermissionCheckbox;
      var $loyaltyTermsCheckbox;
      var $loyaltyErrorMsgs;
      var $emailAddressInput;
      var $phoneNumberInput;
      if (signedIn == '0') signedIn = false;

      // Determine if user has signed in before by looking at the persistent
      // user cookie.
      var persistentCookie = Drupal.ELB.getJSONCookie('persistent_user_cookie');
      var loyalty = persistentCookie.is_loyalty_member == "1";
      var settings = Drupal.settings || {};
      var show_loyalty_points_in_gnav = settings.globals_variables.show_loyalty_points_in_gnav || 0;

      // @TODO: get email address too so we can put it in the input field
      var firstName = persistentCookie.first_name || '';
      firstName = firstName.replace(/\+/g, ' ');
      var firstTime = persistentCookie.first_time;
      var userDetected = !!firstName;

      // Show/hide registration/sign in links on Beaty Feed page based on user detection
      if (userDetected) {
        $('.bfeed-create-account').hide();
        $('.bfeed-sign-in').show();
      } else {
        $('.bfeed-create-account').show();
        $('.bfeed-sign-in').hide();
      }

      var $overlayTemplate = $('#signin-overlay-template', context);
      var overlayContent = $overlayTemplate.html();
      //
      var isUtilitiesLoyalty = $('.page-utilities__loyalty', context).length;
      var $triggerButton = $('.page-utilities__account-button, .js-launch-account, .field-mobile-menu .sign-in---my-account', context);
      if (isUtilitiesLoyalty) {
        // replaced page-utilities__account-button with page-utilities__signin-text
        $triggerButton = $('.page-utilities__signin-text, .js-launch-account, .field-mobile-menu .sign-in---my-account, .loyalty_join_signin .form-submit:last-child', context);
      }

      // Determine the state of the overlay to show:
      var signInOverlayState = $.cookie('signInOverlayState', {path: '/'});
      // Delete the cookie immediately (apparently these are both needed?):
      $.cookie('signInOverlayState', null, {path: '/'});
      $.cookie('signInOverlayState', null);

      var isMobile = !$('body').hasClass('device-pc');
      var $signInPage = $('.sign-in-page', context);
      var isSignInPage = $signInPage.length;
      var colorboxSettings = {
        html: overlayContent,
        className: 'signin-overlay-wrapper',
        width: '100%',
        maxWidth: '1022px',
        fixed: true
      };
      if (isMobile) {
        colorboxSettings.top = '0px';
        colorboxSettings.height = '1000px';
      }

      // User greeting and login/logout link below Account button
      // top SIGN IN or SIGN OUT section
      var userLoginState = $('.user-login-state');
      var $accountText = $('.page-utilities__account-text');

      if( userLoginState.length ) {
        if (isUtilitiesLoyalty) {
          if (signedIn && !isMobile) {
            // user is signed in so show the sign out link
            // "Hi [name!] Sign Out"
            $accountText.show();
            $('.js-is_signed_in', $accountText).removeClass('hidden');
            $('.page-utilities__signin-text').hide();
            //hide signin button on elist page(pc)
            $('.loyalty_mrkt_ldng .js-sign-in-popup').hide();
            if (Drupal.settings.globals_variables.loyalty_gdpr_with_sms && persistentCookie.pc_email_optin && persistentCookie.pc_email_optin !== '0') {
              $('.js-loyalty-gdpr-signup input[name=PC_EMAIL_PROMOTIONS]', context).prop('checked', true);
              $('.js-loyalty-gdpr-signup label.acceptance-checkbox-label', context).hide();
              $('.js-loyalty-gdpr-signup .js-loyalty-gdpr-communication', context).hide();
            }
          } else if (signedIn && isMobile) {
            //hide signin button on elist page(mobile)
            $('.loyalty_market .js-sign-in-popup').hide();
            if (Drupal.settings.globals_variables.loyalty_gdpr_with_sms && persistentCookie.pc_email_optin && persistentCookie.pc_email_optin !== '0') {
              $('.js-loyalty-gdpr-signup input[name=PC_EMAIL_PROMOTIONS]', context).prop('checked', true);
              $('.js-loyalty-gdpr-signup label.acceptance-checkbox-label', context).hide();
              $('.js-loyalty-gdpr-signup .js-loyalty-gdpr-communication', context).hide();
            }
          } else if (!signedIn && !isMobile) {
            if(userDetected) {
              // user is signed out but detected show
              // "Hi [name!] Not You?"
              $accountText.show();
              $('.js-is_recognized', $accountText).removeClass('hidden');
              $('.page-utilities__signin-text').hide();
            } else {
              // user is signed out so show the sign in link
              // "Sign In"
              $('.loyalty_join_signin .js-loyalty-gdpr-signup', context).hide();
              $('.page-utilities__signin-text').show();
              $('.page-utilities__account-text').hide();
            }
          } else if (!signedIn && isMobile) {
            $('.loyalty_join_signin .js-loyalty-gdpr-signup', context).hide();
          }
        } else {
          if (signedIn) {
            $('.user-logged-in').show();
            $('.user-logged-out').hide();
          } else {
            $('.user-logged-out').show();
            $('.user-logged-in').hide();
          }
        }
      }

      // Show JOIN LOYALTY or USERNAME + DISCOUNT
      var userLoyaltyState = $('.user-loyalty-state');
      if( userLoyaltyState.length && isUtilitiesLoyalty ) {
        if (!isMobile) {
          if ( userDetected && loyalty ) {
            // add the discount/tier name to the global navigation under the user name
            lang = generic.rb("language");
            var loyaltyPoints = persistentCookie.points ? persistentCookie.points : 0;
            var levelName = show_loyalty_points_in_gnav ? loyaltyPoints : lang.get('loyalty_panel_benefits_tier_' + persistentCookie.loyalty_level);
            var levelDisc = lang.get('loyalty_panel_benefits_tier_' + persistentCookie.loyalty_level + '_discount');
            levelName = levelName.replace(/\+/g, ' ');

            if (levelName) {
              $(userLoyaltyState).find('.user-logged-in').find('.js-loyalty-discount-value').html(levelDisc);
              $(userLoyaltyState).find('.user-logged-in').find('.js-loyalty-tier-name').html(levelName);
              $(userLoyaltyState).find('.user-logged-in').find('.js-loyalty-points-value').html(loyaltyPoints);
            }

            // show discount
            $('.user-logged-in').show();
            $('.user-logged-out').hide();
            if (persistentCookie.loyalty_level === undefined || persistentCookie.loyalty_level === null || persistentCookie.loyalty_level === '') {
              $('.user-logged-in').hide();
            }

          } else {
            // show join link
            $('.user-logged-out').show();
            $('.user-logged-in').hide();
          }
        }

        // bind the click so when the user clicks 'join e-list' they are shown the popup to enroll
        $('.js-join-elist').click(function(event) {
          event.preventDefault();
          Drupal.behaviors.ELB_loyalty_offer.showSignupFormNow();
        });
      }

      function _launchOverlay(forceReturn, returnURL) {
        $.colorbox(colorboxSettings);

        var $overlay = $('.signin-overlay-wrapper .sign-in-component');

        // Redirect back to the current page
        // var returnURL = '?RETURN_URL=' + window.location.pathname;
        // Generally only registration sends you back to your last page, but
        // there are some cases where sign in can (ie. "Save to Profile" in the
        // Foundation Finder)
        if (forceReturn) {
          $('form', $overlay).each(function(){
            if (!$('input[name=RETURN_URL]', this).length) {
              $(this).append('<input type="hidden" name="RETURN_URL" value="" />');
            }
          });
        }
        returnURL = returnURL || window.location.pathname + window.location.search;

        $('input[name=RETURN_URL]', $overlay).val(returnURL);

        _initForm($overlay);

        if (settings.validate_password_enabled) {
          site.account.initPasswordValidationTooltip();
        }

        // Init selectboxes for desktop:
        if (!isMobile) {
          $('.selectbox', $overlay).selectBox();
          // Apply global js text input behavior:
          Drupal.behaviors.formTextInputs.attach($('.signin-overlay-wrapper'));
        }
        if (Drupal.settings.vk_login_enabled) {
          Drupal.behaviors.vkontakteLogin.init();
        }
        if (Drupal.settings.social_login_enabled) {
          Drupal.behaviors.socialLogin.init($('.signin-overlay-wrapper'));
        }
        if (typeof Drupal.behaviors.signIn === 'object' && typeof Drupal.behaviors.signIn.extendedRuLogin === 'function') {
          Drupal.behaviors.signIn.extendedRuLogin($('.signin-overlay-wrapper'));
        }
      }

      function _initForm($wrapper) {
        $('.sign-in-component', $signInPage).show();
        var $registerForm = $('.sign-in-component__form--registration', $wrapper);
        var $registerConfirmForm = $('.sign-in-component__confirm--registration', $wrapper);
        var $signInForm = $('.sign-in-component__form--sign-in', $wrapper);
        var $forms = $('form', $wrapper);
        var $returnUrls = $('input[name="RETURN_URL"]', $forms);
        var $showPass = $('input[name=SHOW_PASSWORD]', $wrapper);
        var $pass = $('input[type=password]', $registerForm);
        var $error_messages_list = $("ul.error_messages").find('li');
        var feUserCartCookie;
        var signedInValue;
        var registrationFormName;

        if (Drupal.settings && Drupal.settings.reset_return_url && pathname.search('password_request|confirm|signin') < 0) {
          $returnUrls.val(pathname);
        }

        var $form = {};
        $forms.on('submit', function() {
          $form = $(this);
          feUserCartCookie = $.cookie('FE_USER_CART');
          feUserCartCookie = feUserCartCookie?.split('&').map((item) => item.split(':')) || feUserCartCookie;
          signedInValue = feUserCartCookie?.find((item) => item[0] === 'signed_in');
          registrationFormName = $('input[name="_SUBMIT"]', $form).val();
          if (registrationFormName === 'registration_confirm' && parseInt(signedInValue[1]) === 0) {
            window.location.href = '/account/signin.tmpl?_SUBMIT=signout';

            return false;
          }

          if ($('input[name="_TOKEN"]', $form).length && $.cookie('csrftoken')) {
            $('input[name="_TOKEN"]', $form).val($.cookie('csrftoken'));
          }
        });

        // Set the appropriate class on the outer container to tell css what to
        // display. By default we show the registration
        // form, but if the overlay state cookie indicates we just registered or
        // signed in, we show the relevant confirmation screen instead. Finally,
        // if the user's ever logged in on this machine we display the sign in
        // form by default.

        // First off, if there's an error in the form, and we're trying to show
        // a confirmation page, go back a step:
        if (isSignInPage && ($('input.error, select.error', $wrapper).length || $('ul.error_messages').find('li:visible').length)) {
          if (signInOverlayState == 'register-confirm') {
            signInOverlayState = 'register';
          } else if (signInOverlayState == 'signin-confirm') {
            signInOverlayState = 'signin';
          }
        }

        if(isSignInPage && $('#account_lockout\\.\\.').is(":visible")){
          if (signInOverlayState == 'signin-confirm') {
            signInOverlayState = 'signin';
          }
        }

        // Toggle the class:
        if (signInOverlayState == 'register' || signInOverlayState == null) {
          $wrapper.addClass('registration');
        } else if (signInOverlayState == 'register-confirm') {
          $wrapper.addClass('registration-confirmation');
        } else if (signInOverlayState == 'signin-confirm') {
          // $wrapper.addClass('sign-in-confirmation');
        } else if (signInOverlayState == 'signin' || userDetected) {
          $wrapper.addClass('sign-in');
        }

        // if user has registered before then show sign in form
        if ((firstTime == 0) && (signInOverlayState != 'register-confirm') && (userDetected)) {
          $wrapper.addClass('sign-in');
        }

        // Remove any stray error classes that may have ended up on the hidden forms:
        $('form:hidden', $wrapper).find('input.error, select.error').removeClass('error');

        // Preprocess the form:

        $pass.each(function(){
          $(this).after('<div class="visible-pass-wrapper"><input class="visible-pass form-text" type="text" style="display: none;" /></div>');
          if (!isMobile) {
            Drupal.behaviors.formTextInputs.attach($('.visible-pass-wrapper'));
          }
        });
        var $visiblePass = $('.visible-pass', $wrapper);

        // Add the user's first name to the sign in confirmation screen header:
        if (firstName) {
          var $signInConfirmHeader = $('.sign-in-component__confirm--sign-in .sign-in-component__header', $wrapper);
          $signInConfirmHeader.text($signInConfirmHeader.text().replace('first_name', firstName));
        }

        if ($('.sign-in-component__fpw-link', $wrapper).length >0){
          site.signin.forgotPassword({
            resetPassword: true,
            emailNode: $("input#sign-in-component__EMAIL_ADDRESS", $wrapper),
            errorListNode: $(".signin-block__lost-pass-text", $wrapper),
            forgotPasswordLink: $('#forgot-password', $wrapper),
            forgotPasswordNote: $('p#forgot_pw_note', $wrapper)
          });
        }

        // Bind events:

        $showPass.on('change', function(e) {
          var show = $(this).is(':checked');
          $visiblePass.add($pass).toggle();
          if (show) {
            $('.visible-pass', $wrapper).each(function(){
              $(this).val($(this).parent().prev().val()).trigger('blur');
            });
          } else {
            $pass.each(function(){
              $(this).val($(this).next().children().first().val()).trigger('blur');
            });
          }
        });

        // Prevent the sms form from submitting on the register-confirm overlay when the mobile number is blank
        if (signInOverlayState == 'register-confirm') {
          $('.sign-in-component__confirm-options, input[type=submit]').on('click', function() {
            var mobileNumber = $('#sign-in-component__SMSPROMO_MOBILE_NUMBER');
            if ( $(mobileNumber).attr('value') == '') {
              $('input[type=hidden], [name=_SECONDARY_SUBMIT], [value=sms]').remove();
            }
          });
        }

        function validateEmail(data) {
          var reg = /^([A-Za-z0-9_\-.!#$^&*+=.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
          if (!data) {
            return false;
          }
          if (data && reg.test(data) === false) {
            return false;
          }
          return true;
        }

        function validaPhoneNumber(data) {
          // regex for EL TR - match regex from backend, 10 digits only
          var reg = /\d{10}$/;
          if (!data) {
            return false;
          }
          if (data && reg.test(data) === false) {
            return false;
          }
          return true;
        }

        $signInForm.add($registerForm).on('submit', function() {
          // Set the password field to what's in the visible password field if
          // show password is checked
          var showPass = $showPass.is(':checked');
          var $form = $(this);
          if (showPass) {
            $pass.each(function(){
              $(this).val($(this).next().children().first().val());
            });
          }

          // these loyalty form validation rules must be disabled on .tmpl pages and are used only for registration form
          if (settingsLoyalty.loyalty_enabled
              && !location.href.match(/.tmpl/)
              && $form.hasClass('sign-in-component__form--registration')) {
            $dataPrivacyCheckbox = $('.js-data-privacy-consent', context);
            $commercialPermissionCheckbox = $('.js-commercial', context);
            $loyaltyTermsCheckbox = $('.js-accepted-loyalty-terms', context);
            $loyaltyErrorMsgs = $('.js-loyalty-errors', context);
            $loyaltyErrorMsgs.find('li').addClass('hidden');
            $emailAddressInput = $('.js-email', context);
            $phoneNumberInput = $('.js-mobile-number', context);
            var $loyaltyReturnUrl = $('.js-return-url', context);

            if ($commercialPermissionCheckbox.is(':checked') && !validaPhoneNumber($phoneNumberInput.val())) {
              $loyaltyErrorMsgs.find('.validation_error_phone_number').removeClass('hidden');
              return false;
            }

            if (!validateEmail($emailAddressInput.val())) {
              $loyaltyErrorMsgs.find('.validation_error_email_address').removeClass('hidden');
              return false;
            }

            if (!$dataPrivacyCheckbox.is(':checked')) {
              $loyaltyErrorMsgs.find('.validation_error_data_privacy').removeClass('hidden');
              return false;
            }

            if (settingsLoyalty.force_sms_email_agrees && $loyaltyTermsCheckbox.is(':checked') && !$commercialPermissionCheckbox.is(':checked')) {
              $loyaltyErrorMsgs.find('.validation_error_commercial_permission').removeClass('hidden');
              return false;
            }

            //to join loyalty mobile number must be provided - LOYAL-8053
            if (settingsLoyalty.force_sms_email_agrees && $loyaltyTermsCheckbox.is(':checked')
              && ($phoneNumberInput.val() === '' || !validaPhoneNumber($phoneNumberInput.val()))) {
              $loyaltyErrorMsgs.find('.validation_error_phone_number').removeClass('hidden');
              return false;
            }

            if ($loyaltyTermsCheckbox.is(':checked') && settingsLoyaltyRegistrationPopup.return_url && $loyaltyReturnUrl.length) {
              $loyaltyReturnUrl.first().val(settingsLoyaltyRegistrationPopup.return_url);
            }
          }

          // Set a cookie so we remember which form was submitted so we can
          // launch the relevant confirmation overlay on the next page load
          var cookieVal = $(this).hasClass('sign-in-component__form--sign-in') ? 'signin-confirm' : 'register-confirm';
          $.cookie('signInOverlayState', cookieVal, {path: '/'});

        });



        $('.signin-overlay__toggle-form a', $wrapper).on('click.signIn', function(event) {
          event.preventDefault();
          $error_messages_list
          if($error_messages_list){ $error_messages_list.hide();}
          $wrapper.toggleClass('sign-in');
        });

        $('.sign-in-component__close', $wrapper).on('click.signIn', function(event) {
          event.preventDefault();
          $.colorbox.close();
          if (signInOverlayState == 'register-confirm' && signedIn) {
            $( '.my-feed-drawer .drawer-formatter__trigger').trigger('mouseover');
          }
        });

        if (Drupal.settings.globals_variables.adv_legal) {
          $('.js-commercial').on('change', function() {
            if ($(this).is(':checked')) {
              $('.js-mobile-container').removeClass('hidden');
            } else {
              $('.js-mobile-container').addClass('hidden');
            }
          });
          $('#registration .signin-overlay-wrapper #cboxClose, .js-continue-shopping').on('click', function() {
            $('.js-gnav-submit').trigger('click');
          });
        }
      } // /End initForm()

      $triggerButton.on( 'click.signIn', function(event) {
        if (Drupal.settings.globals_variables.loyalty_prevent_sign_in_popup) {
          // send them to signin account
          window.location.href = Drupal.settings.globals_variables.account_enroll_url;
          return false;
        }
        event.preventDefault();

        var forceReturn = $(this).hasClass('js-launch-account--return');
        var returnURL = forceReturn ? $(this).attr('data-return-url') : null;

        // If already signed in, this button works as a link to the account
        // landing.
        if (signedIn) {
          window.location = '/account/index.tmpl';
        }
        // If the form is already on the page, focus on the first element in it
        else if ($('.sign-in-component', context).length) {
          $('.sign-in-component', context).find('.form-text:visible').first().focus();
        } else {
          _launchOverlay(forceReturn, returnURL);
          if (site.facebook) {
            site.facebook.init();
          }
          if(generic.env.isIOS4) {
             popupScroll.destroy();
             popupScroll = null;
             popupScroll = new IScroll('#colorbox',  { mouseWheel: true });
             setTimeout( function(){
               popupScroll.refresh();
             }, 500 ) ;
          }
        }
        if (site.facebook) {
          site.facebook.init();
        }
      });

      // Automatically launch the overlay if the cookie is set and we're not on
      // the dedicated sign in page.
      if (!isSignInPage) {
        // Disabling the sign in confirmation functionality since it was removed from the spec.
        // if ((signInOverlayState == 'register-confirm' || signInOverlayState == 'signin-confirm') && signedIn) {
        if (signInOverlayState == 'register-confirm' && signedIn) {
          _launchOverlay(forceReturn, returnURL);
        }
      }

      // Run initForm directly on the context. This will only really be useful
      // for the sign in page, where the form is already embedded.
      _initForm($('.sign-in-component', context));

      // Ensure this script doesn't break site if perlgem isn't running:
      if (typeof site != 'undefined' && typeof site.userInfoCookie != 'undefined') {
        // enabling to use first_name placeholder in CMS
        var $template = $('.user-login-state');
        if (isUtilitiesLoyalty) {
          $template = $('.page-utilities__account-text');
        }
        if(firstName.length){
          var rendered = Mustache.render( $template.html(), { first_name: firstName } );
          $template.html( rendered );
        }else{
          var $userGreating = $('.user-greeting__name',$template);
          $userGreating.html('');
        }
        // bind to the recogized sign in link in the gnav
        $('.js-is_detected').on('click.signIn', function(event) { triggerOverlay(event); });
        // if user clicks on 'Sign out' link reset recognized user related cookies
        $('.sign-out-link').each(function(event) {
          var $signOutLink = $(this);
          var returnURL = window.location.pathname;
          var signOutURL = $signOutLink.attr('href');
          $signOutLink.attr('href', signOutURL).on('click', function(event) {
            var domain = '.' + window.location.hostname.replace(
              /^.*(esteelauder\.)/,
              function(m, $1) { return $1; }
            );
            var cookieObj = JSON.parse($.cookie('persistent_user_cookie', { path: '/', domain: domain }));
            $.cookie('persistent_user_cookie', JSON.stringify(cookieObj), { path: '/', domain: domain });
            $.cookie("persistent_user_last_purchase", null, { path: '/' });
            $.cookie('expandMyFeedTray', 0, {path: '/'});
            });

        });
      }
    }
  };
})(jQuery);
